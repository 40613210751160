import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _delay from 'lodash.delay'
import { getSecondsDiff, secondsToTimeRemaining } from '../../utils/countdownTimerUtils'
import { Timer } from './Timer'
import { useDispatch, useSelector } from 'react-redux'
import { selectDisableStartEvent, setDisableStartEvent } from '../../reducers/uiSlice'
import { selectCurrentStore } from '../../reducers/storeSlice'

export const EventTimer = ({ title, subtitle, dateTime, onTimeUp }) => {
  const disableStartEvent = useSelector(selectDisableStartEvent)
  const {
    company: {
      companyConfigurations: {
        redirectToCall: { secondsToRedirect },
      },
    },
  } = useSelector(selectCurrentStore)
  const dispatch = useDispatch()
  const [remainingSeconds, setRemaningSeconds] = useState(null)

  useEffect(() => {
    if (remainingSeconds === null || remainingSeconds >= 0) {
      _delay(async () => {
        setRemaningSeconds(getSecondsDiff(dateTime, new Date()))
      }, 1000)
    } else {
      onTimeUp(remainingSeconds)
    }

    if (remainingSeconds !== null && remainingSeconds <= secondsToRedirect && disableStartEvent) {
      dispatch(setDisableStartEvent(false))
    }
  }, [remainingSeconds])

  return (
    <Container>
      <Header>{title}</Header>
      {subtitle && <SecondaryHeader>{subtitle.toUpperCase()}</SecondaryHeader>}
      <Timer remainingTime={secondsToTimeRemaining(remainingSeconds)} />
    </Container>
  )
}

const Container = styled.div`
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
`

const Header = styled.h1`
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 1.938rem;
`
const SecondaryHeader = styled.span`
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
`
EventTimer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dateTime: PropTypes.object.isRequired,
  onTimeUp: PropTypes.func.isRequired,
}
