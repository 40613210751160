import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ShareOutlined } from '@material-ui/icons'
import copy from 'copy-to-clipboard'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { setNotificationMessage, setIsNotificationVisible } from '../../reducers/uiSlice'
import { RoundedButton } from '../Kit/Buttons'
import { useTranslation } from 'react-i18next'
import { useShareUrl } from '../../hooks/useShareUrl'
import { useGoogleAnalytics, useElasticEventTracker, useMatomoAnalytics } from '@gojiraf/analytics'
import { useDevices } from '@gojiraf/responsive'

const ShareButton = () => {
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const { t } = useTranslation()
  const { isDesktop } = useDevices()
  const dispatch = useDispatch()
  const store = useSelector(selectCurrentStore)
  const { urlToShare } = useShareUrl(store)
  const { sendEventPostToElastic } = useElasticEventTracker()

  const showMessage = (message) => {
    dispatch(setNotificationMessage({ notificationMessage: message }))
    dispatch(setIsNotificationVisible({ isNotificationVisible: true }))
  }

  const shareCallLink = async () => {
    gaEventTracker('InCall', 'call-share-button')
    sendEventPostToElastic('call-share-button')
    matomoTrackEvent('InCall', 'call-share-button')

    if (!isDesktop && navigator.share) {
      try {
        await navigator?.share({
          title: '',
          text: '',
          url: urlToShare,
        })
        showMessage(t('uiElements.shareMobile').toUpperCase())
      } catch (error) {
        console.error('Error sharing link', error)
      }
    } else {
      copy(urlToShare)
      showMessage(t('uiElements.copyToClipboard').toUpperCase())
    }
  }

  return (
    <RoundedButton
      id="call-share-button"
      data-test="share-button"
      aria-label="share"
      color="secondary"
      onClick={shareCallLink}
    >
      <ShareOutlined />
    </RoundedButton>
  )
}

export default ShareButton
