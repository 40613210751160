"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// ../../node_modules/@babel/runtime/helpers/interopRequireDefault.js
var require_interopRequireDefault = __commonJS({
  "../../node_modules/@babel/runtime/helpers/interopRequireDefault.js"(exports, module2) {
    function _interopRequireDefault(obj) {
      return obj && obj.__esModule ? obj : {
        "default": obj
      };
    }
    module2.exports = _interopRequireDefault, module2.exports.__esModule = true, module2.exports["default"] = module2.exports;
  }
});

// ../../node_modules/@mui/icons-material/utils/createSvgIcon.js
var require_createSvgIcon = __commonJS({
  "../../node_modules/@mui/icons-material/utils/createSvgIcon.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    Object.defineProperty(exports, "default", {
      enumerable: true,
      get: function() {
        return _utils.createSvgIcon;
      }
    });
    var _utils = require("@mui/material/utils");
  }
});

// ../../node_modules/@mui/icons-material/Search.js
var require_Search = __commonJS({
  "../../node_modules/@mui/icons-material/Search.js"(exports) {
    "use strict";
    var _interopRequireDefault = require_interopRequireDefault();
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = void 0;
    var _createSvgIcon = _interopRequireDefault(require_createSvgIcon());
    var _jsxRuntime = require("react/jsx-runtime");
    var _default = (0, _createSvgIcon.default)(/* @__PURE__ */ (0, _jsxRuntime.jsx)("path", {
      d: "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
    }), "Search");
    exports.default = _default;
  }
});

// ../../node_modules/@mui/icons-material/HighlightOff.js
var require_HighlightOff = __commonJS({
  "../../node_modules/@mui/icons-material/HighlightOff.js"(exports) {
    "use strict";
    var _interopRequireDefault = require_interopRequireDefault();
    Object.defineProperty(exports, "__esModule", {
      value: true
    });
    exports.default = void 0;
    var _createSvgIcon = _interopRequireDefault(require_createSvgIcon());
    var _jsxRuntime = require("react/jsx-runtime");
    var _default = (0, _createSvgIcon.default)(/* @__PURE__ */ (0, _jsxRuntime.jsx)("path", {
      d: "M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    }), "HighlightOff");
    exports.default = _default;
  }
});

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  Button: () => Button,
  Carousel: () => Carousel,
  CarouselContent: () => CarouselContent,
  CarouselItem: () => CarouselItem,
  Cart: () => Cart,
  CartCard: () => CartCard,
  CartCardCarso: () => CartCardCarso,
  CartCount: () => CartCount,
  CartItem: () => CartItem,
  CartView: () => CartView,
  DeliveryMethods: () => DeliveryMethods,
  Draggable: () => Draggable,
  FeatureProduct: () => FeatureProduct,
  FormattedPrice: () => FormattedPrice,
  MainHeading: () => MainHeading,
  Modal: () => Modal,
  PDPContainer: () => PDPContainer,
  ProductImage: () => ProductImage,
  Products: () => Products,
  ProductsProvider: () => ProductsProvider,
  ProductsView: () => ProductsView,
  StyledBadge: () => StyledBadge,
  darken: () => darken,
  defaultTheme: () => defaultTheme,
  formatNumber: () => formatNumber,
  getBadgeDiscount: () => getBadgeDiscount,
  getCurrency: () => getCurrency,
  getDynamicText: () => getDynamicText,
  toHsl: () => toHsl,
  useCart: () => useCart,
  usePDP: () => usePDP,
  useProducts: () => useProducts
});
module.exports = __toCommonJS(src_exports);

// src/Kit/Button.tsx
var import_classnames = __toESM(require("classnames"));
var import_useutm = require("@gojiraf/useutm");

// src/utils/getDynamicText.ts
var getDynamicText = ({ paymentType, textsOptions }) => {
  const currentText = textsOptions.storeCustomizationText || textsOptions[paymentType] || textsOptions.defaultText;
  return currentText;
};

// src/utils/formatNumber.ts
var LOCAL_LANGUAGES = {
  AR: "es-AR",
  CL: "es-CL",
  MX: "es-AR",
  UY: "es-UY",
  CO: "es-CO",
  US: "es-EC",
  ES: "es-ES",
  BR: "pt-BR",
  PE: "es-PE",
  PY: "es-PY",
  EC: "es-EC"
};
var LOCAL_CURRENCIES = {
  AR: "ARS",
  CL: "CLP",
  MX: "ARS",
  UY: "UYU",
  CO: "COP",
  US: "USD",
  ES: "EUR",
  BR: "BRL",
  PE: "PEN",
  PY: "PYG",
  EC: "USD"
};
var formatNumber = ({ price, countryCode = "AR" }) => {
  const localeLanguage = LOCAL_LANGUAGES[countryCode] || "es-AR";
  const formattedNumber = Intl.NumberFormat(localeLanguage, {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
  return formattedNumber;
};
var getCurrency = (countryCode) => {
  const currencyCode = LOCAL_CURRENCIES[countryCode] || "ARS";
  const localeLanguage = LOCAL_LANGUAGES[countryCode];
  const currencyCoin = new Intl.NumberFormat(localeLanguage, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0
  }).format(1);
  const currencySymbol = currencyCoin.replace(/\d/g, "").trim();
  return currencySymbol;
};
var getBadgeDiscount = (originalPrice, price) => {
  const discount = Number(((originalPrice - price) / originalPrice * 100).toFixed(0));
  return { discountText: `-${discount}%`, discountValue: discount };
};

// src/utils/toHsl.ts
var import_color = __toESM(require("color"));
function toHsl(hex) {
  const [h, s, l] = (0, import_color.default)(hex).hsl().array().map((value) => Math.round(value));
  return `${h} ${s}% ${l}%`;
}

// src/utils/darken.ts
var import_color2 = __toESM(require("color"));
function darken(hex) {
  const darkenHex = (0, import_color2.default)(hex).darken(0.25).string();
  return toHsl(darkenHex);
}

// src/utils/defaultTheme.ts
var import_tailwind = __toESM(require("tailwind-config/tailwind.config"));
var defaultTheme = {
  colors: import_tailwind.default.daisyui.themes[0].gojiraf
};

// src/hooks/useTheme.ts
var import_state = require("state");
var useTheme = () => {
  var _a2, _b;
  const store = (0, import_state.useStore)((storeState) => storeState.store);
  const storeIsUndefined = store === void 0;
  return {
    theme: storeIsUndefined ? defaultTheme : (_b = (_a2 = store.storeConfigurations) == null ? void 0 : _a2.storeCustomization) == null ? void 0 : _b.theme
  };
};

// src/hooks/useUTMMediumFromURL.ts
var import_react = require("react");
var useUtmMediumFromURL = () => {
  const [utmMedium, setUtmMedium] = (0, import_react.useState)("");
  (0, import_react.useEffect)(() => {
    const getUtmMediumFromURL = () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return urlSearchParams.get("utm_medium") || "";
    };
    const medium = getUtmMediumFromURL();
    setUtmMedium(medium);
  }, []);
  return utmMedium;
};

// src/Kit/Button.tsx
var import_jsx_runtime = require("react/jsx-runtime");
function Button(_a2) {
  var _b = _a2, {
    children,
    backgroundColor,
    className = "",
    ref
  } = _b, props = __objRest(_b, [
    "children",
    "backgroundColor",
    "className",
    "ref"
  ]);
  const { theme } = useTheme();
  const selectedBackground = backgroundColor || theme.colors.primary;
  const style = { "--p": toHsl(selectedBackground), "--pf": darken(selectedBackground) };
  const utmMedium = useUtmMediumFromURL();
  const { isTotemDevice } = (0, import_useutm.useUtm)(utmMedium);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    "button",
    __spreadProps(__spreadValues({
      ref,
      type: "button",
      style,
      className: (0, import_classnames.default)(`btn btn-primary h-12 text-white rounded-full disabled:bg-gray-300 disabled:text-white px-16 hover:opacity-80 ${isTotemDevice && "w-full"}`, className)
    }, props), {
      children
    })
  );
}

// src/Kit/Draggable.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
function Draggable() {
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)("div", { children: "Draggable" });
}

// src/Kit/MainHeading.tsx
var import_classnames2 = __toESM(require("classnames"));
var import_icons = require("icons");
var import_jsx_runtime3 = require("react/jsx-runtime");
function MainHeading({ title, onBackClicked, className }) {
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("div", { className: (0, import_classnames2.default)("flex items-center justify-between", className), children: /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("div", { className: "flex justify-center gap-2 items-center", children: [
    onBackClicked !== void 0 ? /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(import_icons.Icons.Chevron.Left, { className: "text-3xl cursor-pointer", onClick: onBackClicked }) : null,
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("h2", { className: "card-title capitalize text-base sm:text-2xl font-medium tracking-wider", children: title })
  ] }) });
}

// src/Kit/Modal.tsx
var import_framer_motion = require("framer-motion");
var import_classnames3 = __toESM(require("classnames"));
var import_responsive = require("@gojiraf/responsive");
var import_jsx_runtime4 = require("react/jsx-runtime");
var overlayVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};
var panelVariants = {
  hidden: { y: 1e3 },
  visible: {
    y: 0,
    transition: {
      type: "spring",
      damping: 30,
      stiffness: 300
    }
  }
};
var containerSize = {
  FULL: "h-full",
  LARGE: "h-[80%]",
  SMALL: "h-[50%]"
};
var motionSize = {
  FULL: "h-full",
  LARGE: "h-48",
  SMALL: "h-12"
};
function Modal({
  size = "FULL",
  children,
  onClose
}) {
  const handleDragEnd = (event, info) => {
    if (info.offset.y > 200) {
      onClose();
    }
  };
  const { isDesktop } = (0, import_responsive.useDevices)();
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsxs)("div", { className: "absolute h-full z-10 w-full flex flex-col", children: [
    size === "LARGE" || size === "FULL" ? /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
      import_framer_motion.motion.div,
      {
        className: `${(0, import_classnames3.default)(motionSize[size])} grow`,
        id: "modal-overlay",
        initial: "hidden",
        animate: "visible",
        exit: "hidden",
        variants: overlayVariants,
        onClick: onClose
      }
    ) : null,
    /* @__PURE__ */ (0, import_jsx_runtime4.jsxs)(
      import_framer_motion.motion.div,
      {
        className: `${(0, import_classnames3.default)(containerSize[size])} relative pb-8 bg-base-100`,
        style: { borderRadius: "1rem 1rem 0 0" },
        id: "modal-panel",
        initial: "hidden",
        animate: "visible",
        exit: "hidden",
        drag: "y",
        dragConstraints: {
          top: 0,
          bottom: 0
        },
        dragElastic: 0.8,
        onDragEnd: handleDragEnd,
        variants: panelVariants,
        children: [
          isDesktop === false ? /* @__PURE__ */ (0, import_jsx_runtime4.jsx)("div", { className: "my-4 mx-auto w-16 h-2 bg-slate-300 rounded-xl" }) : null,
          /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
            import_framer_motion.motion.div,
            {
              className: "h-full",
              drag: "y",
              dragConstraints: {
                top: 0,
                bottom: 0
              },
              dragElastic: 0,
              dragMomentum: false,
              dragDirectionLock: true,
              children
            }
          )
        ]
      }
    )
  ] });
}

// src/Kit/Carousel.tsx
var import_react2 = __toESM(require("react"));
var import_embla_carousel_react = __toESM(require("embla-carousel-react"));
var import_styled = __toESM(require("@emotion/styled"));
var import_jsx_runtime5 = require("react/jsx-runtime");
var Embla = import_styled.default.div`
  overflow: hidden;
  padding: 0 1rem 1rem 1rem;
`;
var EmblaContainer = import_styled.default.div`
  display: flex;
  gap: 20px; 
  padding: 0 6%; 
`;
var EmblaSlide = import_styled.default.div`
  flex: 0 0 90%;
  position: relative;
  min-width: 0;
`;
var Carousel = ({
  orientation = "horizontal",
  className,
  children,
  featuredProductId
}) => {
  const [emblaRef, emblaApi] = (0, import_embla_carousel_react.default)({
    axis: orientation === "horizontal" ? "x" : "y",
    loop: true,
    containScroll: "trimSnaps",
    align: "start",
    slidesToScroll: 1
  });
  const productList = import_react2.default.Children.toArray(children).flatMap((child) => {
    const productArray = Array.isArray(child.props.children) ? child.props.children : [child.props.children];
    return productArray.flatMap((product) => product.props.productsList);
  });
  const featuredProductIndex = productList.findIndex(
    (product) => product.id === featuredProductId
  );
  (0, import_react2.useEffect)(() => {
    if (emblaApi && featuredProductIndex !== -1) {
      emblaApi.scrollTo(featuredProductIndex);
    }
  }, [emblaApi, featuredProductIndex]);
  return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(Embla, { className, ref: emblaRef, children });
};
var CarouselContent = ({ className, children }) => /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(EmblaContainer, { className, children });
var CarouselItem = ({ id, children }) => /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(EmblaSlide, { id, children });

// src/Kit/FormattedPrice.tsx
var import_jsx_runtime6 = require("react/jsx-runtime");
function FormattedPrice({ formattedNum, discount = false, currency }) {
  const [integerPart, decimalPart] = formattedNum.split(new RegExp("(?<=,)"));
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_jsx_runtime6.Fragment, { children: currency !== "\u20AC" ? /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)("div", { className: "flex font-bold", children: [
    /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)("p", { children: [
      currency,
      "\xA0"
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("div", { className: `${discount ? "line-through" : ""}`, children: integerPart }),
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
      "span",
      {
        className: `${discount && decimalPart !== void 0 ? "line-through" : ""} text-xxs`,
        children: decimalPart
      }
    )
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)("div", { className: "flex font-bold", children: [
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("div", { className: `${discount ? "line-through" : ""}`, children: integerPart }),
    /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(
      "span",
      {
        className: `${discount && decimalPart !== void 0 ? "line-through" : ""} text-xxs`,
        children: [
          decimalPart,
          "\xA0"
        ]
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("p", { children: currency })
  ] }) });
}

// src/Products/ProductsView.tsx
var import_react14 = require("react");
var import_state11 = require("state");
var import_react_i18next14 = require("react-i18next");

// src/PDP/PDPContainer.tsx
var import_react7 = require("react");

// src/Cart/useCart.ts
var import_xstate = require("xstate");
var import_zustand = __toESM(require("zustand"));
var import_zustand_xstate_middleware = __toESM(require("zustand-xstate-middleware"));
var cartMachine = (0, import_xstate.createMachine)({
  context: { variants: /* @__PURE__ */ new Map() },
  tsTypes: {},
  schema: { context: {}, events: {} },
  initial: "Empty",
  states: {
    Empty: {
      on: {
        "Add product variant": {
          target: "Fill",
          actions: "addProductOnCart"
        }
      }
    },
    Fill: {
      on: {
        "Add product variant": {
          target: "Fill",
          actions: "addProductOnCart",
          internal: false
        },
        "Remove product variant": [
          {
            target: "Empty",
            cond: "cart is empty after removing the product",
            actions: "removeProductOnCart"
          },
          {
            target: "Fill",
            actions: "removeProductOnCart",
            internal: false
          }
        ],
        "Products list changed": {
          target: "Fill",
          actions: "checkVariantsOnCart",
          internal: false
        },
        "Reset Cart": {
          target: "Empty",
          actions: "resetCart"
        }
      }
    }
  },
  id: "cartMachine"
}, {
  guards: {
    "cart is empty after removing the product": (context) => context.variants.size === 0
  },
  actions: {
    addProductOnCart: (0, import_xstate.assign)((context, event) => {
      var _a2;
      const newProducts = new Map(context.variants);
      const { productVariantId, quantity } = event;
      const currentQuantity = (_a2 = context.variants.get(productVariantId)) != null ? _a2 : 0;
      newProducts.set(productVariantId, currentQuantity + quantity);
      return {
        variants: newProducts
      };
    }),
    removeProductOnCart: (0, import_xstate.assign)((context, event) => {
      var _a2;
      const newProducts = new Map(context.variants);
      const { productVariantId } = event;
      const quantity = (_a2 = context.variants.get(productVariantId)) != null ? _a2 : 1;
      if (quantity === 1) {
        newProducts.delete(productVariantId);
      } else {
        newProducts.set(productVariantId, quantity - 1);
      }
      return {
        variants: newProducts
      };
    }),
    checkVariantsOnCart: (0, import_xstate.assign)((context, event) => {
      const { productsList } = event;
      const newVariants = /* @__PURE__ */ new Map();
      context.variants.forEach((quantity, skuId) => {
        const variantExists = productsList.some(
          (product) => product.skus.some((sku) => sku.id === skuId)
        );
        if (variantExists === true) {
          newVariants.set(skuId, quantity);
        }
      });
      return {
        variants: newVariants
      };
    }),
    resetCart: (0, import_xstate.assign)(() => ({
      variants: /* @__PURE__ */ new Map()
    }))
  }
});
var useCart = (0, import_zustand.default)((0, import_zustand_xstate_middleware.default)(cartMachine));

// src/Products/useProducts.ts
var import_react3 = require("react");
var import_react_query = require("@tanstack/react-query");

// src/Products/queries.ts
var productsKeys = {
  all: ["products"],
  lists: () => [...productsKeys.all, "list"],
  list: (filters) => [...productsKeys.lists(), { filters }],
  details: () => [...productsKeys.all, "detail"],
  detail: (id) => [...productsKeys.details(), id]
};

// src/Products/productsService.ts
var {
  REACT_APP_PRODUCTS_MICROSERVICE_QUERY
} = process.env;
var fetchProducts = (callId) => __async(void 0, null, function* () {
  const products = yield fetch(
    `${REACT_APP_PRODUCTS_MICROSERVICE_QUERY}/api/product-query/${callId}`
  ).then((res) => res.json());
  return products;
});
var highlightProduct = (_0) => __async(void 0, [_0], function* ({ callId, productId, accessToken }) {
  try {
    yield fetch(
      `${REACT_APP_PRODUCTS_MICROSERVICE_QUERY}/api/product-query/${callId}/product/main`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          ids: [productId]
        })
      }
    );
  } catch (error) {
    console.error("Error highlighting new product", error);
  }
});

// src/Products/productsConfig.ts
var _a;
var refreshInterval = Number((_a = process.env.REACT_APP_PRODUCTS_REFRESH_INTERVAL) != null ? _a : 6e4);
var productsConfig = {
  refreshInterval
};

// src/Products/useProducts.ts
var useProducts = ({ callId = void 0 } = {}) => {
  const { send: cartSend } = useCart();
  const [runQuery, setRunQuery] = (0, import_react3.useState)(true);
  const [productsFiltered, setProductsFiltered] = (0, import_react3.useState)([]);
  const { data: catalog, isLoading } = (0, import_react_query.useQuery)(
    productsKeys.all,
    () => fetchProducts(callId),
    {
      refetchInterval: productsConfig.refreshInterval,
      enabled: !!callId && runQuery,
      refetchOnWindowFocus: true,
      onSuccess(data) {
        cartSend({
          type: "Products list changed",
          productsList: data.products
        });
      }
    }
  );
  const prevFeaturedProduct = (0, import_react3.useRef)(null);
  const [animateFeaturedProduct, setAnimateFeaturedProduct] = (0, import_react3.useState)(false);
  const toogleFeatureProductAnimation = () => {
    setAnimateFeaturedProduct(true);
    setTimeout(() => {
      setAnimateFeaturedProduct(false);
    }, 500);
  };
  const findFeaturedProduct = (currentCatalog) => {
    if (currentCatalog === void 0 || currentCatalog.products === void 0) {
      return null;
    }
    return currentCatalog.products.find((product) => product.id === (catalog == null ? void 0 : catalog.featuredProducts[0]));
  };
  const featuredProduct = (0, import_react3.useMemo)(() => findFeaturedProduct(catalog), [catalog]);
  (0, import_react3.useEffect)(() => {
    var _a2;
    if (((_a2 = prevFeaturedProduct.current) == null ? void 0 : _a2.id) !== (featuredProduct == null ? void 0 : featuredProduct.id)) {
      toogleFeatureProductAnimation();
      prevFeaturedProduct.current = featuredProduct;
    }
  }, [featuredProduct]);
  const getProductById = (productId) => catalog == null ? void 0 : catalog.products.find((product) => product.id === productId);
  const getProductsBySearchTerm = (searchTerm) => {
    if (!searchTerm) {
      setProductsFiltered([]);
      setRunQuery(true);
      return;
    }
    if (catalog && searchTerm) {
      setRunQuery(false);
      const filterApplied = catalog.products.filter(
        (product) => product.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
      );
      if (filterApplied.length === 0) {
        setProductsFiltered(null);
      } else {
        setProductsFiltered(filterApplied);
      }
    }
  };
  return {
    products: (productsFiltered == null ? void 0 : productsFiltered.length) === 0 ? catalog == null ? void 0 : catalog.products : productsFiltered,
    featuredProduct,
    loading: isLoading,
    getProductById,
    animateFeaturedProduct,
    getProductsBySearchTerm
  };
};

// src/PDP/ProductDetailPage.tsx
var import_react6 = require("react");
var import_icons5 = require("icons");
var import_lodash2 = __toESM(require("lodash.isequal"));
var import_lodash3 = __toESM(require("lodash.uniqby"));
var import_react_i18next2 = require("react-i18next");
var import_state4 = require("state");
var import_responsive2 = require("@gojiraf/responsive");
var import_useutm2 = require("@gojiraf/useutm");

// src/PDP/OptionsSelector.tsx
var import_react4 = require("react");
var import_lodash = __toESM(require("lodash.isequal"));
var import_jsx_runtime7 = require("react/jsx-runtime");
function Select({
  option,
  currentValue,
  skus,
  selectedValues,
  setSelectedValue,
  defaultValue
}) {
  const getSelectedValuesWithOption = (valueId) => {
    const selectedValuesObject = Object.fromEntries(selectedValues);
    selectedValuesObject[option.id] = valueId;
    return selectedValuesObject;
  };
  const getFinalVariantOptions = (variant, selectedValuesWithOption) => Object.fromEntries(
    Object.keys(variant.options).map((key) => {
      if (selectedValuesWithOption[key]) {
        return [[key], variant.options[key]];
      }
      return [[key], null];
    })
  );
  const UpperCaseVariantName = (variantName) => variantName.charAt(0).toUpperCase() + variantName.slice(1);
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(
    "select",
    {
      defaultValue,
      className: "myCustomSelect border border-black rounded-full px-4 whitespace-nowrap overflow-hidden text-ellipsis py-1 text-left text-xs",
      value: currentValue,
      onChange: (e) => setSelectedValue(e.target.value),
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("option", { disabled: true, selected: true, children: option.name }),
        option.values.map((value) => {
          const isOptionAvailable = !!(skus == null ? void 0 : skus.find((variant) => {
            const selectedValuesWithOption = getSelectedValuesWithOption(
              value.id
            );
            const finalVariantOptions = getFinalVariantOptions(
              variant,
              selectedValuesWithOption
            );
            const variantExist = (0, import_lodash.default)(
              finalVariantOptions,
              selectedValuesWithOption
            );
            return variantExist;
          }));
          return isOptionAvailable ? /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("option", { value: value.id, children: UpperCaseVariantName(value.value) }) : null;
        })
      ]
    }
  );
}
var generateValues = (options) => {
  const values = /* @__PURE__ */ new Map();
  options.forEach((option) => values.set(option.id, null));
  return values;
};
function OptionsSelector({
  options = [],
  skus,
  onOptionsSelected,
  defaultValue
}) {
  options.forEach((option) => {
    if (!option.id)
      option.id = option.name;
  });
  const [selectedValues, setSelectedValues] = (0, import_react4.useState)(generateValues(options));
  (0, import_react4.useEffect)(() => {
    let isVariantSelected = true;
    const selectedOptions = {};
    selectedValues.forEach((valueId, optionId) => {
      if (valueId === null) {
        isVariantSelected = false;
      } else {
        selectedOptions[optionId] = valueId;
      }
    });
    if (isVariantSelected === true) {
      onOptionsSelected(selectedOptions);
    }
  }, [selectedValues]);
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("div", { className: "grid grid-cols-2 gap-2", children: options.map((option) => /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
    Select,
    {
      defaultValue,
      option,
      currentValue: selectedValues.get(option.id),
      skus,
      selectedValues,
      setSelectedValue: (valueId) => {
        const valuesCopy = new Map(selectedValues);
        valuesCopy.set(option.id, valueId);
        setSelectedValues(valuesCopy);
      }
    }
  )) });
}

// src/Kit/QuantityInput.tsx
var import_icons2 = require("icons");
var import_jsx_runtime8 = require("react/jsx-runtime");
function QuantityInput({
  quantity = 1,
  noQuantityLimitReached = true,
  canDelete = false,
  onAdd,
  onRemove
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("div", { className: "flex items-center gap-4", children: [
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      "button",
      {
        type: "button",
        onClick: (event) => {
          onRemove();
          event.stopPropagation();
        },
        children: quantity === 1 ? /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_jsx_runtime8.Fragment, { children: canDelete === true ? /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_icons2.Icons.Trash.Outline, {}) : /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_icons2.Icons.Minus.Fill, { className: "text-gray-300 cursor-not-allowed" }) }) : /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_icons2.Icons.Minus.Fill, {})
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("div", { className: "", children: quantity }),
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
      "button",
      {
        type: "button",
        onClick: (event) => {
          onAdd();
          event.stopPropagation();
        },
        children: noQuantityLimitReached === true ? /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_icons2.Icons.Plus.Fill, {}) : /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_icons2.Icons.Plus.Fill, { className: "text-gray-300 cursor-not-allowed" })
      }
    )
  ] });
}

// src/Cart/CartCount.tsx
var import_icons3 = require("icons");
var import_classnames4 = __toESM(require("classnames"));
var import_state2 = require("state");
var import_analytics = require("@gojiraf/analytics");
var import_material = require("@mui/material");
var import_jsx_runtime9 = require("react/jsx-runtime");
var StyledBadge = (0, import_material.styled)(import_material.Badge)(({ customStyles }) => ({
  "& .MuiBadge-badge": __spreadValues({
    fontFamily: "Montserrat",
    backgroundColor: "#F93700"
  }, customStyles)
}));
function CartCountPresentation(_a2) {
  var _b = _a2, { cartItemsAmount, className } = _b, props = __objRest(_b, ["cartItemsAmount", "className"]);
  const { send: sendViews } = (0, import_state2.useViews)();
  const { gaEventTracker } = (0, import_analytics.useGoogleAnalytics)();
  const { matomoTrackEvent } = (0, import_analytics.useMatomoAnalytics)();
  const handleGoToCart = () => {
    gaEventTracker("InCall > Products", "go-to-cart");
    matomoTrackEvent("InCall > Products", "go-to-cart");
    sendViews({
      type: "SHOW_CART"
    });
  };
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
    "div",
    {
      onClick: handleGoToCart,
      className: "cursor-pointer relative flex justify-center items-center",
      children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(StyledBadge, { badgeContent: cartItemsAmount, max: 99, color: "primary", children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_icons3.Icons.Cart.Outline, __spreadValues({ className: (0, import_classnames4.default)("text-2xl", className) }, props)) })
    }
  );
}
function CartCount(props) {
  var _a2;
  const { state } = useCart();
  const totalItems = (_a2 = Array.from(state.context.variants)) == null ? void 0 : _a2.reduce(
    (acc, v) => acc + v[1],
    0
  );
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(CartCountPresentation, __spreadValues({ cartItemsAmount: totalItems }, props));
}

// src/Kit/Accordion.tsx
var import_analytics2 = require("@gojiraf/analytics");
var import_icons4 = require("icons");
var import_react5 = require("react");
var import_jsx_runtime10 = require("react/jsx-runtime");
function Accordion({ title, content }) {
  const { matomoTrackEvent } = (0, import_analytics2.useMatomoAnalytics)();
  const [isOpen, setIsOpen] = (0, import_react5.useState)(true);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    matomoTrackEvent("InCall > Products", isOpen ? "click-on-product-description-uncollapse" : "click-on-product-description-collapse");
    (0, import_analytics2.sendEventToElastic)(null, null, "InCall > Products", isOpen ? "click-on-product-description-uncollapse" : "click-on-product-description-collapse", "");
  };
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)("div", { children: [
    /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("div", { className: "flex justify-between items-center", children: /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(
      "button",
      {
        type: "button",
        className: "w-full py-2 text-left font-medium flex justify-between items-center",
        onClick: toggleAccordion,
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("span", { className: "font-semibold", children: title }),
          /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("span", { className: `transform ${isOpen ? "rotate-180" : "rotate-0"} transition-transform`, children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(import_icons4.Icons.Expand, {}) })
        ]
      }
    ) }),
    isOpen && /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("div", { className: "py-4", children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("p", { children: content }) })
  ] });
}

// src/Kit/PDPPrices.tsx
var import_react_i18next = require("react-i18next");
var import_state3 = require("state");
var import_jsx_runtime11 = require("react/jsx-runtime");
var discountStyles = {
  fontFamily: "Montserrat",
  display: "flex",
  alignItems: "center",
  alignSelf: "center",
  height: "1.25rem",
  backgroundColor: "rgb(249, 55, 0)",
  fontSize: "0.688rem",
  fontWeight: "600",
  borderRadius: "0.625rem",
  padding: "0 0.375rem",
  color: "white"
};
function PDPPrices({ from, to, hasLabel = false }) {
  const { t } = (0, import_react_i18next.useTranslation)();
  const { discountText, discountValue } = getBadgeDiscount(to, from);
  const { countryCode } = (0, import_state3.useStore)((storeState) => storeState.store);
  const fromPrice = String(String(formatNumber({ price: from, countryCode })));
  const toPrice = String(formatNumber({ price: to, countryCode }));
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsxs)("div", { className: "flex flex-col justify-center gap-1 relative", children: [
    hasLabel && /* @__PURE__ */ (0, import_jsx_runtime11.jsxs)(import_jsx_runtime11.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("div", {}),
      /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("span", { className: "text-xxs font-medium uppercase absolute -top-[10px]", children: t("products.from") })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime11.jsxs)("div", { className: "flex gap-2", children: [
      /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("span", { className: "text-lg font-semibold flex", children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
        FormattedPrice,
        {
          formattedNum: fromPrice,
          currency: getCurrency(countryCode)
        }
      ) }),
      discountValue > 0 && /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("span", { style: discountStyles, children: discountText })
    ] }),
    to > from && /* @__PURE__ */ (0, import_jsx_runtime11.jsx)("span", { className: "text-xs font-medium text-gray-400 flex", children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
      FormattedPrice,
      {
        formattedNum: toPrice,
        discount: discountValue > 0,
        currency: getCurrency(countryCode)
      }
    ) })
  ] });
}

// src/PDP/ProductDetailPage.tsx
var import_analytics3 = require("@gojiraf/analytics");
var import_jsx_runtime12 = require("react/jsx-runtime");
function ProductDetailPage({
  product,
  total,
  quantity = 1,
  itemsInCart,
  selectedVariant,
  hasOwnCheckoutIntegration,
  isLightIntegration,
  handlePIPLightIntegration,
  setSelectedVariant,
  onQuantityChanged = () => {
  },
  goBack = () => {
  },
  onAddProductToCart = () => {
  },
  onHandleProductCheckout
}) {
  var _a2, _b, _c, _d, _e, _f, _g;
  const { t, i18n } = (0, import_react_i18next2.useTranslation)();
  const containerRef = (0, import_react6.useRef)(null);
  const store = (0, import_state4.useStore)((storeState) => storeState.store);
  const { active, price } = store.storeConfigurations.features.shipping;
  const addToCartButtonText = (_b = (_a2 = store == null ? void 0 : store.storeConfigurations) == null ? void 0 : _a2.storeCustomization) == null ? void 0 : _b.addToCartButtonText;
  const { type: paymentType } = store.paymentGateways.find((paymentGatewayItem) => paymentGatewayItem.isDefault === true);
  const [canAdd, setCanAdd] = (0, import_react6.useState)(false);
  const [canAddError, setCanAddError] = (0, import_react6.useState)(false);
  const [goToCheckout, setGoToCheckout] = (0, import_react6.useState)(false);
  const productImage = (_d = (_c = selectedVariant.images[0].imageUrl) != null ? _c : product.images[0].imageUrl) != null ? _d : "";
  const { isDesktop } = (0, import_responsive2.useDevices)();
  const uniqueProductChoice = product.skus.length === 1;
  const utmMedium = useUtmMediumFromURL();
  const { isTotemDevice } = (0, import_useutm2.useUtm)(utmMedium);
  const { matomoTrackEvent } = (0, import_analytics3.useMatomoAnalytics)();
  const handleOptionsSelected = (optionsSelected) => {
    const selectedVariantIndexFound = product.skus.findIndex(
      (variant) => (0, import_lodash2.default)(variant.options, optionsSelected)
    );
    if (selectedVariantIndexFound !== -1) {
      setSelectedVariant(selectedVariantIndexFound);
      setCanAdd(true);
    } else {
      setCanAdd(false);
    }
  };
  (0, import_react6.useEffect)(() => {
    i18n.changeLanguage(store.lang);
  }, []);
  (0, import_react6.useEffect)(() => {
    if (uniqueProductChoice || !product.variants) {
      if (uniqueProductChoice)
        setSelectedVariant(0);
      setCanAdd(true);
    }
  }, [product]);
  const getDefaultValue = () => {
    const defaultVariantSelected = uniqueProductChoice ? product.skus[0].id : void 0;
    return defaultVariantSelected;
  };
  const noQuantityLimitReached = (selectedVariant == null ? void 0 : selectedVariant.limitPerOrder) === void 0 || quantity < (selectedVariant == null ? void 0 : selectedVariant.limitPerOrder);
  const renderDescription = !!product.description && product.description !== product.name;
  const scrollToVariantNotSelected = () => {
    const container = containerRef == null ? void 0 : containerRef.current;
    if (container) {
      container.scrollTo({
        top: container.clientHeight,
        behavior: "smooth"
      });
    }
  };
  const handleAddButton = () => {
    if (isLightIntegration) {
      handlePIPLightIntegration(product, selectedVariant == null ? void 0 : selectedVariant.externalUrl);
      return;
    }
    if (!canAdd) {
      setCanAddError(true);
      scrollToVariantNotSelected();
      return;
    }
    onAddProductToCart(selectedVariant, quantity);
    goBack();
  };
  const handleCheckoutButton = () => {
    if (!canAdd) {
      setCanAddError(true);
      scrollToVariantNotSelected();
      return;
    }
    onAddProductToCart(selectedVariant, quantity);
    setGoToCheckout(true);
  };
  (0, import_react6.useEffect)(() => {
    if (goToCheckout) {
      onHandleProductCheckout({ total, selectedDeliveryMethod: "unavailable" });
      setGoToCheckout(false);
    }
  }, [itemsInCart]);
  const hasLabel = !canAdd && ((_e = product.skus) == null ? void 0 : _e.length) > 1 && (0, import_lodash3.default)(product.skus, "price").length > 1;
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "h-full card overflow-hidden bg-base-100", children: [
    /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { ref: containerRef, style: { boxShadow: "inset 0 -5px 4px -5px rgba(0, 0, 0, 0.25)" }, className: "card-body transition-all h-full pt-4 sm:pt-8 overflow-y-scroll overflow-x-hidden bg-base-100", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex flex-col grow h-full", children: [
      isDesktop === true ? /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex justify-between items-center mb-7 p-1", children: [
        /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex items-center", children: [
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_icons5.Icons.Chevron.Left, { className: "text-3xl cursor-pointer", onClick: goBack }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("span", { className: "card-title capitalize text-base sm:text-2xl font-medium leading-3 tracking-wider", children: t("pdp.products") })
        ] }),
        isLightIntegration ? null : /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(CartCount, {})
      ] }) : /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex mb-7 w-full", children: [
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "flex justify-start", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(import_icons5.Icons.Chevron.Left, { className: "text-3xl cursor-pointer ", onClick: goBack }) }),
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "mr-[1.875rem] flex justify-center w-full", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("span", { className: "card-title capitalize text-base sm:text-2xl font-medium leading-3 tracking-wider", children: t("pdp.products") }) })
      ] }),
      /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "flex flex-col items-center justify-between grow h-80", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex flex-col w-full gap-6", children: [
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: `flex flex-col justify-center ${isTotemDevice ? "width_img_product_totem height_img_product_totem" : "w-64 h-64"} mobileXs:w-48 mobileXs:h-48 mx-auto rounded-lg shadow-xl`, children: product.images.length > 0 ? /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
          "img",
          {
            className: "h-full rounded-lg object-cover",
            src: productImage,
            alt: (_f = product.description) != null ? _f : ""
          }
        ) : null }),
        /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { className: "flex w-full items-start", children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("span", { style: { WebkitLineClamp: "3", WebkitBoxOrient: "vertical", display: "-webkit-box" }, className: "overflow-hidden text-ellipsis text-sm font-medium capitalize break-words", children: product.name }) }),
        /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex flex-col gap-4", children: [
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
            PDPPrices,
            {
              hasLabel,
              from: selectedVariant.price,
              to: selectedVariant.originalPrice
            }
          ),
          active && price === 0 && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("span", { className: "font-medium font-size text-xs", children: t("pdp.freeShipping") })
        ] }),
        isLightIntegration ? null : /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(import_jsx_runtime12.Fragment, { children: [
          ((_g = product.variants) == null ? void 0 : _g.length) > 0 && /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "w-full", children: [
            /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
              OptionsSelector,
              {
                defaultValue: getDefaultValue(),
                options: product.variants,
                skus: product.skus,
                onOptionsSelected: handleOptionsSelected
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { children: canAddError === true && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("span", { className: "flex items-center ml-1 mt-2 font-medium text-red-500 font-size text-xs", children: t("pdp.errorMessageVariants") }) })
          ] }),
          /* @__PURE__ */ (0, import_jsx_runtime12.jsx)("div", { children: /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
            QuantityInput,
            {
              canDelete: false,
              noQuantityLimitReached,
              quantity,
              onAdd: () => {
                if (noQuantityLimitReached)
                  onQuantityChanged(quantity + 1);
                matomoTrackEvent("InCall > Products > PDP", `increase-quantity-of-products-pdp [${product.name}]`);
                (0, import_analytics3.sendEventToElastic)(null, null, "InCall > Products > PDP", `increase-quantity-of-products-pdp [${product.name}]`, "");
              },
              onRemove: () => {
                if (quantity > 1)
                  onQuantityChanged(quantity - 1);
                matomoTrackEvent("InCall > Products > PDP", `decrease/delete-quantity-product-pdp [${product.name}]`);
                (0, import_analytics3.sendEventToElastic)(null, null, "InCall > Products > PDP", `decrease/delete-quantity-product-pdp [${product.name}]`, "");
              }
            }
          ) })
        ] }),
        renderDescription && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(Accordion, { title: t("pdp.description"), content: product.description })
      ] }) })
    ] }) }),
    /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)("div", { className: "flex flex-col justify-center w-full p-6", children: [
      /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
        Button,
        {
          id: "product-detail-add-to-cart-button",
          className: "uppercase tracking-widest w-full mb-2",
          onClick: handleAddButton,
          children: getDynamicText({
            paymentType,
            textsOptions: {
              light: t("pdp.lightIntegration"),
              to_agree: t("pdp.addToAgree"),
              storeCustomizationText: addToCartButtonText,
              defaultText: t("pdp.addToCart")
            }
          })
        }
      ),
      hasOwnCheckoutIntegration && /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
        Button,
        {
          id: "product-detail-add-to-cart-button",
          className: "uppercase tracking-widest w-full text-black bg-white hover:bg-white",
          onClick: handleCheckoutButton,
          children: getDynamicText({
            paymentType,
            textsOptions: {
              light: t("pdp.lightIntegration"),
              to_agree: t("pdp.addToAgree"),
              storeCustomizationText: addToCartButtonText,
              defaultText: t("pdp.checkout")
            }
          })
        }
      )
    ] })
  ] });
}

// src/PDP/usePDP.ts
var import_zustand2 = __toESM(require("zustand"));
var import_xstate2 = require("xstate");
var import_zustand_xstate_middleware2 = __toESM(require("zustand-xstate-middleware"));
var pdpMachine = (0, import_xstate2.createMachine)({
  context: { productId: null },
  tsTypes: {},
  schema: { context: {}, events: {} },
  initial: "init",
  states: {
    init: {
      on: {
        SET_PRODUCT: {
          target: "showingProduct",
          actions: "assignProduct"
        }
      }
    },
    showingProduct: {
      on: {
        SET_PRODUCT: {
          target: "showingProduct",
          actions: "assignProduct",
          internal: false
        }
      }
    }
  },
  id: "pdpMachine"
}, {
  actions: {
    assignProduct: (0, import_xstate2.assign)((context, event) => ({
      productId: event.productId
    }))
  }
});
var usePDP = (0, import_zustand2.default)((0, import_zustand_xstate_middleware2.default)(pdpMachine));

// src/PDP/PDPContainer.tsx
var import_jsx_runtime13 = require("react/jsx-runtime");
function PDPContainer({
  goBack,
  isLightIntegration,
  handleBuyClicked,
  getItemsInCart,
  handleAddProductToCart,
  hasOwnCheckoutIntegration,
  handlePIPLightIntegration
}) {
  const { state } = usePDP();
  const { state: stateUseCart } = useCart();
  const { getProductById, products } = useProducts();
  const product = (0, import_react7.useMemo)(
    () => getProductById(state.context.productId),
    [state.context.productId, products]
  );
  const [quantity, setQuantity] = (0, import_react7.useState)(1);
  const [selectedVariantIndex, setSelectedVariantIndex] = (0, import_react7.useState)(0);
  const selectedVariant = (0, import_react7.useMemo)(() => {
    if (!product) {
      goBack();
      return null;
    }
    return product.skus[selectedVariantIndex];
  }, [selectedVariantIndex, product]);
  const itemsInCart = (0, import_react7.useMemo)(() => getItemsInCart(), [stateUseCart.context.variants]);
  const total = (0, import_react7.useMemo)(() => itemsInCart.reduce((acc, item) => acc + item.price * item.quantity, 0), [itemsInCart]);
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_jsx_runtime13.Fragment, { children: selectedVariant && /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
    ProductDetailPage,
    {
      hasOwnCheckoutIntegration,
      total,
      handlePIPLightIntegration,
      isLightIntegration,
      selectedVariant,
      setSelectedVariant: (index) => {
        setSelectedVariantIndex(index);
      },
      itemsInCart,
      onHandleProductCheckout: handleBuyClicked,
      quantity,
      onQuantityChanged: (newQuantity) => setQuantity(newQuantity),
      product,
      goBack,
      onAddProductToCart: handleAddProductToCart
    }
  ) });
}

// src/Products/Products.tsx
var import_react13 = require("react");
var import_analytics8 = require("@gojiraf/analytics");

// src/Products/ProductsCard.tsx
var import_react_i18next4 = require("react-i18next");
var import_state5 = require("state");
var import_responsive3 = require("@gojiraf/responsive");
var import_analytics4 = require("@gojiraf/analytics");

// src/Kit/Card.tsx
var import_icons6 = require("icons");
var import_react8 = require("react");
var import_useutm3 = require("@gojiraf/useutm");

// src/utils/useScroll.ts
var import_zustand3 = __toESM(require("zustand"));
var useScrollStore = (0, import_zustand3.default)((set) => ({
  scrollPosition: 0,
  setScrollPosition: (position) => set({ scrollPosition: position })
}));
var useScroll_default = useScrollStore;

// src/Kit/Card.tsx
var import_jsx_runtime14 = require("react/jsx-runtime");
function Card({
  title,
  isDesktop = true,
  onGoBack,
  renderTop,
  renderMiddle,
  renderBottom
}) {
  const utmMedium = useUtmMediumFromURL();
  const { isTotemDevice } = (0, import_useutm3.useUtm)(utmMedium);
  const scrollContainerRef = (0, import_react8.useRef)(null);
  const { scrollPosition, setScrollPosition } = useScroll_default();
  const handleScroll = () => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollTop);
    }
  };
  (0, import_react8.useEffect)(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
      scrollContainer.scrollTop = scrollPosition;
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollContainerRef]);
  return /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("div", { id: "products", className: "h-full card bg-base-100", children: /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)("div", { className: "card-body px-0 pt-4 sm:pt-8 snap-y overflow-y-auto overflow-x-hidden", children: [
    /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)("div", { className: `${isDesktop ? "justify-between" : "justify-center"} flex items-center px-8`, children: [
      /* @__PURE__ */ (0, import_jsx_runtime14.jsxs)("div", { className: "flex justify-center gap-2 items-center", children: [
        onGoBack !== void 0 ? /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(import_icons6.Icons.Chevron.Left, { className: "text-3xl cursor-pointer", onClick: onGoBack }) : null,
        /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("h2", { className: "card-title text-base sm:text-2xl font-medium tracking-wider", children: title })
      ] }),
      renderTop
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("div", { ref: scrollContainerRef, className: ` overflow-y-auto overflow-x-hidden grow flex flex-col pl-8 pr-4 mr-4 ${isTotemDevice && "gap-32"}`, children: renderMiddle }),
    renderBottom ? /* @__PURE__ */ (0, import_jsx_runtime14.jsx)("div", { className: "card-actions justify-center items-center mt-4 p-6", children: renderBottom }) : null
  ] }) });
}

// src/Products/SearchBar.tsx
var import_react9 = require("react");
var import_material2 = require("@mui/material");
var import_Search = __toESM(require_Search());
var import_HighlightOff = __toESM(require_HighlightOff());
var import_react_i18next3 = require("react-i18next");
var import_jsx_runtime15 = require("react/jsx-runtime");
function SearchBar({
  getProductsBySearchTerm = () => {
  },
  setSearchTerm,
  searchTerm
}) {
  const { t } = (0, import_react_i18next3.useTranslation)();
  const placeHolderText = t("products.searchBar");
  const handleSearch = () => {
    getProductsBySearchTerm(searchTerm);
  };
  (0, import_react9.useEffect)(() => {
    getProductsBySearchTerm("");
  }, []);
  return /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)("div", { className: "flex justify-between relative rounded-full border border-black", children: [
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_material2.IconButton, { color: "inherit", "aria-label": "search", onClick: handleSearch, children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_Search.default, {}) }),
    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      import_material2.InputBase,
      {
        color: "primary",
        fullWidth: true,
        sx: { fontSize: "0.875rem", padding: "0", fontWeight: "400" },
        placeholder: placeHolderText,
        inputProps: { "aria-label": "search" },
        value: searchTerm,
        onChange: (event) => {
          setSearchTerm(event.target.value);
          getProductsBySearchTerm(event.target.value);
        }
      }
    ),
    searchTerm.length > 0 && /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
      import_material2.IconButton,
      {
        color: "inherit",
        "aria-label": "Clear search bar",
        onClick: () => {
          setSearchTerm("");
          getProductsBySearchTerm("");
        },
        children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(import_HighlightOff.default, {})
      }
    )
  ] });
}

// src/Products/ProductsCard.tsx
var import_jsx_runtime16 = require("react/jsx-runtime");
function ProductsCard({
  canBuy = false,
  isLightIntegration,
  getProductsBySearchTerm,
  setSearchTerm,
  searchTerm,
  paymentType,
  renderList
}) {
  const { gaEventTracker } = (0, import_analytics4.useGoogleAnalytics)();
  const { matomoTrackEvent } = (0, import_analytics4.useMatomoAnalytics)();
  const { isDesktop } = (0, import_responsive3.useDevices)();
  const { t } = (0, import_react_i18next4.useTranslation)();
  const { send } = (0, import_state5.useViews)();
  const showCart = () => {
    gaEventTracker("InCall > Products", "product-catalog-buy-button-to-checkout");
    matomoTrackEvent("InCall > Products", "product-catalog-buy-button-to-checkout");
    send({ type: "SHOW_CART" });
  };
  return /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
    Card,
    {
      isDesktop,
      title: isDesktop ? t("products.products") : t("products.productsList"),
      renderTop: !isDesktop || isLightIntegration ? null : /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(CartCount, {}),
      renderMiddle: /* @__PURE__ */ (0, import_jsx_runtime16.jsxs)(import_jsx_runtime16.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
          SearchBar,
          {
            setSearchTerm,
            searchTerm,
            getProductsBySearchTerm
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime16.jsx)("div", { className: "flex flex-col divide-y  divide-black", children: renderList })
      ] }),
      renderBottom: !isDesktop || isLightIntegration ? null : /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(
        Button,
        {
          className: "text-sm font-semibold uppercase leading-4 tracking-widest",
          id: "product-catalog-buy-button-to-checkout",
          disabled: canBuy === false,
          onClick: () => showCart(),
          children: getDynamicText({ paymentType, textsOptions: { to_agree: t("products.continue"), storeCustomizationText: "", defaultText: t("products.buy") } })
        }
      )
    }
  );
}

// src/Products/ProductItem.tsx
var import_react_i18next12 = require("react-i18next");
var import_auth = require("@gojiraf/auth");
var import_responsive5 = require("@gojiraf/responsive");
var import_analytics7 = require("@gojiraf/analytics");
var import_classnames11 = __toESM(require("classnames"));
var import_lodash4 = __toESM(require("lodash.uniqby"));
var import_state10 = require("state");

// src/Kit/ProductPrice.tsx
var import_react_i18next5 = require("react-i18next");
var import_state6 = require("state");
var import_jsx_runtime17 = require("react/jsx-runtime");
function ProductPrice({
  from,
  to,
  hasLabel = false
}) {
  const { t } = (0, import_react_i18next5.useTranslation)();
  const { countryCode } = (0, import_state6.useStore)((storeState) => storeState.store);
  const fromPrice = String(formatNumber({ price: from, countryCode }));
  const toPrice = String(formatNumber({ price: to, countryCode }));
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)("div", { "data-test": "price-container", className: "flex flex-col justify-center items-end gap-1 relative", children: [
    hasLabel && /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)(import_jsx_runtime17.Fragment, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", {}),
      /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("span", { className: "text-xxs font-medium uppercase self-start", children: t("products.from") })
    ] }),
    /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "flex font-semibold", children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
      FormattedPrice,
      {
        formattedNum: fromPrice,
        currency: getCurrency(countryCode)
      }
    ) }),
    to > from && /* @__PURE__ */ (0, import_jsx_runtime17.jsx)("div", { className: "flex  font-medium text-gray-400 self-start", children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
      FormattedPrice,
      {
        formattedNum: toPrice,
        discount: to > from,
        currency: getCurrency(countryCode)
      }
    ) })
  ] });
}

// src/Kit/ListItem.tsx
var import_classnames10 = __toESM(require("classnames"));
var import_useutm6 = require("@gojiraf/useutm");

// src/Cart/CartItem.tsx
var import_analytics5 = require("@gojiraf/analytics");
var import_jsx_runtime18 = require("react/jsx-runtime");
function CartItem({
  item,
  onProductAdded = () => {
  },
  onProductRemoved = () => {
  }
}) {
  const { matomoTrackEvent } = (0, import_analytics5.useMatomoAnalytics)();
  const noQuantityLimitReached = item.limitPerOrder === void 0 || item.quantity < item.limitPerOrder;
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
    ListItem,
    {
      className: "py-7",
      itemId: item.id,
      imageUrl: item.images[0].imageUrl,
      originalPrice: item.originalPrice,
      price: item.price,
      onProductClicked: () => {
      },
      title: item.product.name,
      renderBottomLeft: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
        "span",
        {
          style: {
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            display: "-webkit-box"
          },
          className: "grow overflow-hidden text-ellipsis text-xs font-medium capitalize self-end break-words text-gray-400",
          children: Object.entries(item.options).map(([optionId, valueId]) => {
            const foundOption = item.product.variants.find(
              (option) => option.id === optionId
            );
            const foundValue = foundOption == null ? void 0 : foundOption.values.find((optionValue) => {
              const value = optionValue.id || optionValue.value;
              return value === valueId;
            });
            return foundValue.value;
          }).join(" / ")
        }
      ),
      renderMidLeft: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
        ProductPrice,
        {
          from: item.price,
          to: item.originalPrice
        }
      ),
      renderBottomRight: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
        QuantityInput,
        {
          canDelete: true,
          noQuantityLimitReached,
          quantity: item.quantity,
          onAdd: () => {
            if (noQuantityLimitReached)
              onProductAdded(item.id);
            matomoTrackEvent("InCall > Products", `increase-quantity-of-products [${item.product.name}]`);
            (0, import_analytics5.sendEventToElastic)(null, null, "InCall > Products", `increase-quantity-of-products`, `[${item.product.name}]`);
          },
          onRemove: () => {
            onProductRemoved(item.id);
            matomoTrackEvent("InCall > Products", `decrease/delete-quantity-product [${item.product.name}]`);
            (0, import_analytics5.sendEventToElastic)(null, null, "InCall > Products", `decrease/delete-quantity-product`, `[${item.product.name}]`);
          }
        }
      )
    }
  );
}

// src/Cart/CartCard.tsx
var import_react10 = require("react");
var import_react_i18next7 = require("react-i18next");
var import_state7 = require("state");
var import_analytics6 = require("@gojiraf/analytics");
var import_useutm4 = require("@gojiraf/useutm");

// src/Cart/DeliveryMethods.tsx
var import_react_i18next6 = require("react-i18next");

// src/Kit/Radio.tsx
var import_classnames5 = __toESM(require("classnames"));
var import_jsx_runtime19 = require("react/jsx-runtime");
function Radio(_a2) {
  var _b = _a2, { className, text } = _b, props = __objRest(_b, ["className", "text"]);
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("div", { className: "form-control", children: /* @__PURE__ */ (0, import_jsx_runtime19.jsxs)("label", { className: "label cursor-pointer justify-start", children: [
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(
      "input",
      __spreadValues({
        type: "radio",
        className: (0, import_classnames5.default)("radio radio-xs checked:bg-black border border-gray-300", className)
      }, props)
    ),
    /* @__PURE__ */ (0, import_jsx_runtime19.jsx)("span", { className: "ml-2 label-text text-black text-sm first-letter:capitalize", children: text })
  ] }) });
}

// src/Cart/DeliveryMethods.tsx
var import_jsx_runtime20 = require("react/jsx-runtime");
function DeliveryMethods({
  hasTakeAway,
  hasDelivery,
  selectedDelivery,
  onSelectedDelivery
}) {
  const { t } = (0, import_react_i18next6.useTranslation)();
  return /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)("div", { className: "flex flex-col grow gap-2", children: [
    hasTakeAway === true && /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
      Radio,
      {
        text: t("cart.store pickup"),
        value: 1 /* takeaway */,
        checked: selectedDelivery === "takeaway",
        onChange: () => onSelectedDelivery("takeaway")
      }
    ),
    hasDelivery === true && /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
      Radio,
      {
        text: t("cart.home delivery"),
        value: 2 /* delivery */,
        checked: selectedDelivery === "delivery",
        onChange: () => onSelectedDelivery("delivery")
      }
    )
  ] });
}

// src/Cart/CartCard.tsx
var import_jsx_runtime21 = require("react/jsx-runtime");
function ShadowPixel() {
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("img", { alt: "shadow pixel", src: "https://ads01.groovinads.com/grv/grvredir.os?IdOffer=393998390&idvar=1&IdImp=&idu=1658936282213302&IdSegment=45004724&IdDataS=21156&IdDataSource=21156&IdADS=117266&IdADSItem=520720&product_sku=TID0000000&bt=&Country=CL&Variations=1&Url=https%3A%2F%2Fads01.groovinads.com%2Fblank.os&IdClient=1076" });
}
function CartCard({
  canBuy = false,
  total = 0,
  renderList,
  paymentType,
  cartHeaderText,
  payButtonText,
  defaultDeliveryMethod = "takeaway",
  onBuyClicked = () => {
  },
  onBackClicked = () => {
  }
}) {
  const { gaEventTracker } = (0, import_analytics6.useGoogleAnalytics)();
  const { matomoTrackEvent } = (0, import_analytics6.useMatomoAnalytics)();
  const { t } = (0, import_react_i18next7.useTranslation)();
  const [showPixel, setShowPixel] = (0, import_react10.useState)(false);
  const [selectedDeliveryMethod, setSelectedDelivery] = (0, import_react10.useState)(
    defaultDeliveryMethod
  );
  const {
    countryCode,
    company: { companyConfigurations: { shippingMethods } }
  } = (0, import_state7.useStore)((storeState) => storeState.store);
  const hasTakeaway = shippingMethods.includes("takeaway");
  const hasDelivery = shippingMethods.includes("delivery");
  const utmMedium = useUtmMediumFromURL();
  const { isTotemDevice } = (0, import_useutm4.useUtm)(utmMedium);
  (0, import_react10.useEffect)(() => {
    if (defaultDeliveryMethod === "unavailable" || defaultDeliveryMethod === "to_agree")
      return;
    if (hasTakeaway)
      setSelectedDelivery("takeaway");
    if (!hasTakeaway && hasDelivery)
      setSelectedDelivery("delivery");
  }, [shippingMethods]);
  const handleOnBuyClicked = () => {
    gaEventTracker("InCall > Products", "cart-buy-button-to-checkout");
    matomoTrackEvent("InCall > Products", "cart-buy-button-to-checkout");
    onBuyClicked({
      total,
      selectedDeliveryMethod
    });
  };
  const price = String(formatNumber({ price: total, countryCode }));
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
    Card,
    {
      title: cartHeaderText,
      onGoBack: onBackClicked,
      renderMiddle: /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)(import_jsx_runtime21.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("div", { className: `flex flex-col divide-y divide-black ${isTotemDevice && "gap-32"}`, children: renderList }),
        /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)("div", { className: "flex justify-end items-center gap-2 border-t border-t-black pt-2", children: [
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("span", { className: "text-base capitalize", children: t("cart.total") }),
          /* @__PURE__ */ (0, import_jsx_runtime21.jsx)("span", { className: "text-sm font-semibold flex", children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
            FormattedPrice,
            {
              formattedNum: price,
              currency: getCurrency(countryCode)
            }
          ) })
        ] }),
        selectedDeliveryMethod !== "unavailable" && selectedDeliveryMethod !== "to_agree" ? /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
          DeliveryMethods,
          {
            hasDelivery,
            hasTakeAway: hasTakeaway,
            selectedDelivery: selectedDeliveryMethod,
            onSelectedDelivery: setSelectedDelivery
          }
        ) : null
      ] }),
      renderBottom: /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)(
        Button,
        {
          className: "text-sm font-semibold uppercase leading-4 tracking-widest",
          id: "cart-buy-button-to-checkout",
          disabled: canBuy === false,
          onClick: () => {
            if (paymentType === "cencosud_paris")
              setShowPixel(true);
            handleOnBuyClicked();
          },
          children: [
            showPixel === true && /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(ShadowPixel, {}),
            payButtonText
          ]
        }
      )
    }
  );
}

// src/Cart/Cart.tsx
var import_state9 = require("state");

// src/Cart/CartCardCarso.tsx
var import_react11 = require("react");
var import_react_i18next8 = require("react-i18next");
var import_state8 = require("state");
var import_responsive4 = require("@gojiraf/responsive");

// src/Kit/ButtonCarso.tsx
var import_classnames6 = __toESM(require("classnames"));
var import_jsx_runtime22 = require("react/jsx-runtime");
function ButtonCarso(_a2) {
  var _b = _a2, {
    children,
    className = "",
    href
  } = _b, props = __objRest(_b, [
    "children",
    "className",
    "href"
  ]);
  const { theme } = useTheme();
  const style = { "--p": toHsl(theme.colors.primary), "--pf": darken(theme.colors.primary) };
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
    "a",
    __spreadProps(__spreadValues({
      href,
      target: "_blank",
      rel: "noreferrer",
      style,
      className: (0, import_classnames6.default)("btn btn-primary h-12 text-white rounded-full disabled:bg-gray-300 disabled:text-white xs:px-2 md:px-4 lg:px-8 xl:px-16 hover:opacity-80", className)
    }, props), {
      children
    })
  );
}

// src/Cart/CartCardCarso.tsx
var import_jsx_runtime23 = require("react/jsx-runtime");
function CartCardCarso({
  canBuy = false,
  total = 0,
  renderList,
  cartHeaderText,
  payButtonText,
  defaultDeliveryMethod = "takeaway",
  onBuyClicked,
  onBackClicked = () => {
  }
}) {
  const { t } = (0, import_react_i18next8.useTranslation)();
  const { isDesktop } = (0, import_responsive4.useDevices)();
  const [selectedDeliveryMethod, setSelectedDelivery] = (0, import_react11.useState)(
    defaultDeliveryMethod
  );
  const {
    countryCode,
    paymentGateways,
    company: { companyConfigurations: { shippingMethods } }
  } = (0, import_state8.useStore)((storeState) => storeState.store);
  const hasTakeaway = shippingMethods.includes("takeaway");
  const hasDelivery = shippingMethods.includes("delivery");
  (0, import_react11.useEffect)(() => {
    if (defaultDeliveryMethod === "unavailable")
      return;
    if (hasTakeaway)
      setSelectedDelivery("takeaway");
    if (!hasTakeaway && hasDelivery)
      setSelectedDelivery("delivery");
  }, [shippingMethods]);
  const handleOnBuyClicked = () => {
    onBuyClicked({
      total,
      selectedDeliveryMethod
    });
  };
  const { state: cartState } = useCart();
  const { products } = useProducts();
  const items = (0, import_react11.useMemo)(() => {
    const cartItems = [];
    cartState.context.variants.forEach((quantity, variantId) => {
      let variantFound;
      const productFound = products == null ? void 0 : products.find((product) => {
        variantFound = product.skus.find((variant) => variant.id === variantId);
        if (variantFound !== void 0) {
          return product;
        }
        return void 0;
      });
      if (productFound !== void 0 && variantFound !== void 0) {
        cartItems.push(__spreadValues({
          quantity,
          product: productFound
        }, variantFound));
      }
    });
    return cartItems;
  }, [cartState.context.variants]);
  const getCheckoutUrl = () => {
    const cartQuery = items.map((item) => {
      if (!item.sku || item.sku === "") {
        throw new Error("Some of the products in cart hasn't a sku");
      }
      const [productId, childrenSku] = item.sku.split("-");
      return {
        product_id: productId,
        children_sku: childrenSku != null ? childrenSku : "0",
        quantity: item.quantity.toString()
      };
    });
    const encodedCartQuery = window.btoa(JSON.stringify(cartQuery));
    const paymentGateway = paymentGateways.find((paymentGatewayItem) => paymentGatewayItem.isDefault === true);
    const redirectUrl = `${paymentGateway.urlCheckout}livestream/${encodedCartQuery}`;
    return redirectUrl;
  };
  const [urlCheckout, setUrlCheckout] = (0, import_react11.useState)("");
  (0, import_react11.useEffect)(() => {
    if (canBuy === false) {
      setUrlCheckout("javascript:void(0)");
    } else {
      setUrlCheckout(getCheckoutUrl());
    }
  }, [items]);
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
    Card,
    {
      isDesktop,
      title: cartHeaderText,
      onGoBack: onBackClicked,
      renderMiddle: /* @__PURE__ */ (0, import_jsx_runtime23.jsxs)(import_jsx_runtime23.Fragment, { children: [
        /* @__PURE__ */ (0, import_jsx_runtime23.jsx)("div", { className: "flex flex-col divide-y divide-black", children: renderList }),
        /* @__PURE__ */ (0, import_jsx_runtime23.jsxs)("div", { className: "flex justify-end items-center gap-2 border-t border-t-black pt-2", children: [
          /* @__PURE__ */ (0, import_jsx_runtime23.jsx)("span", { className: "text-base capitalize", children: t("cart.total") }),
          /* @__PURE__ */ (0, import_jsx_runtime23.jsx)("span", { className: "text-sm font-semibold", children: formatNumber({ price: total, countryCode }) })
        ] }),
        selectedDeliveryMethod !== "unavailable" ? /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
          DeliveryMethods,
          {
            hasDelivery,
            hasTakeAway: hasTakeaway,
            selectedDelivery: selectedDeliveryMethod,
            onSelectedDelivery: setSelectedDelivery
          }
        ) : null
      ] }),
      renderBottom: /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
        ButtonCarso,
        {
          className: "text-sm font-semibold uppercase leading-4 tracking-widest",
          id: "cart-buy-ButtonCarso-to-checkout",
          href: urlCheckout,
          onClick: handleOnBuyClicked,
          children: payButtonText
        }
      )
    }
  );
}

// src/Cart/CartList.tsx
var import_react_i18next10 = require("react-i18next");

// src/Kit/List.tsx
var import_icons7 = require("icons");
var import_react_i18next9 = require("react-i18next");

// src/Kit/ButtonViewProduct.tsx
var import_classnames7 = __toESM(require("classnames"));
var import_jsx_runtime24 = require("react/jsx-runtime");
function ButtonViewProduct(_a2) {
  var _b = _a2, {
    children,
    className = ""
  } = _b, props = __objRest(_b, [
    "children",
    "className"
  ]);
  const { theme } = useTheme();
  const style = { "--p": toHsl(theme.colors.primary), "--pf": darken(theme.colors.primary) };
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
    "button",
    __spreadProps(__spreadValues({
      type: "button",
      style,
      className: (0, import_classnames7.default)("btn btn-primary rounded-full min-h-full px-0", className)
    }, props), {
      children
    })
  );
}

// src/Kit/List.tsx
var import_jsx_runtime25 = require("react/jsx-runtime");
function List({
  emptyListText,
  clearSearch,
  items,
  renderItem
}) {
  const { t } = (0, import_react_i18next9.useTranslation)();
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, { children: items === null ? /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "grow w-5/6 flex flex-col gap-4 mt-16 justify-center items-center self-center", children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_icons7.Icons.WrongSearch, {}),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("span", { className: "text-center", children: t("products.noResultsFound") }),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
      ButtonViewProduct,
      {
        id: "catalog-no-products-found",
        className: "text-black bg-white hover:bg-white text-[10px] font-light uppercase leading-4 tracking-widest min-h-0 h-8 px-4",
        onClick: clearSearch,
        children: t("products.viewOtherProducts")
      }
    )
  ] }) : items.length === 0 ? /* @__PURE__ */ (0, import_jsx_runtime25.jsxs)("div", { className: "grow w-full flex flex-col gap-4 mt-16 justify-center items-center", children: [
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_icons7.Icons.LocalMall, {}),
    /* @__PURE__ */ (0, import_jsx_runtime25.jsx)("span", { className: "text-center", children: emptyListText })
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, { children: items.sort((a, b) => (b.main === true) - (a.main === true)).map((item, index) => /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(import_jsx_runtime25.Fragment, { children: renderItem(item, index) })) }) });
}

// src/Cart/CartList.tsx
var import_jsx_runtime26 = require("react/jsx-runtime");
function CartList({
  items,
  renderItem
}) {
  const { t } = (0, import_react_i18next10.useTranslation)();
  return /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
    List,
    {
      clearSearch: () => {
      },
      emptyListText: t("products.productListEmpty"),
      items,
      renderItem
    }
  );
}

// src/Cart/Cart.tsx
var import_jsx_runtime27 = require("react/jsx-runtime");
function Cart(_a2) {
  var _b = _a2, {
    total = 0,
    canBuy = false,
    items = [],
    cartHeaderText,
    payButtonText,
    onBuyClicked,
    onProductAdded = () => {
    },
    onProductRemoved = () => {
    }
  } = _b, props = __objRest(_b, [
    "total",
    "canBuy",
    "items",
    "cartHeaderText",
    "payButtonText",
    "onBuyClicked",
    "onProductAdded",
    "onProductRemoved"
  ]);
  const { paymentGateways } = (0, import_state9.useStore)((storeState) => storeState.store);
  const paymentGateway = paymentGateways.find((paymentGatewayItem) => paymentGatewayItem.isDefault === true);
  return /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(import_jsx_runtime27.Fragment, { children: paymentGateway.type === "carso" ? /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
    CartCardCarso,
    __spreadProps(__spreadValues({}, props), {
      cartHeaderText,
      payButtonText,
      total,
      canBuy,
      onBuyClicked,
      renderList: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
        CartList,
        {
          items,
          renderItem: (item) => /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
            CartItem,
            {
              item,
              onProductAdded: () => onProductAdded(item.id),
              onProductRemoved: () => onProductRemoved(item.id)
            },
            item.id
          )
        }
      )
    })
  ) : /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
    CartCard,
    __spreadProps(__spreadValues({}, props), {
      paymentType: paymentGateway.type,
      cartHeaderText,
      payButtonText,
      total,
      canBuy,
      onBuyClicked,
      renderList: /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
        CartList,
        {
          items,
          renderItem: (item) => /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
            CartItem,
            {
              item,
              onProductAdded: () => onProductAdded(item.id),
              onProductRemoved: () => onProductRemoved(item.id)
            },
            item.id
          )
        }
      )
    })
  ) });
}

// src/Cart/CartView.tsx
var import_react12 = require("react");
var import_react_i18next11 = require("react-i18next");
var import_logger = require("@gojiraf/logger");

// src/Cart/EmptyCart.tsx
var import_icons8 = require("icons");
var import_classnames8 = __toESM(require("classnames"));
var import_jsx_runtime28 = require("react/jsx-runtime");
function EmptyCart({
  title,
  subtitle,
  onBackClicked
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime28.jsx)("div", { id: "products", className: "h-full card bg-base-100", children: /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)("div", { className: "card-body pt-4 sm:pt-8", children: [
    /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(MainHeading, { title, onBackClicked }),
    /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)("div", { className: "flex items-center justify-between mt-6", children: [
      /* @__PURE__ */ (0, import_jsx_runtime28.jsx)("span", { children: subtitle }),
      /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(import_icons8.Icons.Cart.Outline, { className: (0, import_classnames8.default)("text-3xl font-bold") })
    ] })
  ] }) });
}

// src/Cart/CartView.tsx
var import_jsx_runtime29 = require("react/jsx-runtime");
function CartView(_a2) {
  var _b = _a2, {
    cartHeaderText
  } = _b, props = __objRest(_b, [
    "cartHeaderText"
  ]);
  const { products } = useProducts();
  const { send, state: cartState } = useCart();
  const { t } = (0, import_react_i18next11.useTranslation)();
  const { addLog } = (0, import_logger.useLogger)();
  const handleProductAdded = (variantId) => {
    send({
      type: "Add product variant",
      productVariantId: variantId,
      quantity: 1
    });
    addLog({
      event: "PRODUCT_ADDED_TO_CART",
      data: {
        variantId
      }
    });
  };
  const handleProductRemoved = (variantId) => {
    send({
      type: "Remove product variant",
      productVariantId: variantId
    });
    addLog({
      event: "PRODUCT_REMOVED_FROM_CART",
      data: {
        variantId
      }
    });
  };
  const items = (0, import_react12.useMemo)(() => {
    const cartItems = [];
    cartState.context.variants.forEach((quantity, variantId) => {
      let variantFound;
      const productFound = products == null ? void 0 : products.find((product) => {
        variantFound = product.skus.find((variant) => variant.id === variantId);
        if (variantFound !== void 0) {
          return product;
        }
        return void 0;
      });
      if (productFound !== void 0 && variantFound !== void 0) {
        cartItems.push(__spreadValues({
          quantity,
          product: productFound
        }, variantFound));
      }
    });
    return cartItems;
  }, [cartState.context.variants]);
  const total = (0, import_react12.useMemo)(() => items.reduce((acc, item) => acc + item.price * item.quantity, 0), [items]);
  const isEmpty = (0, import_react12.useMemo)(() => items.length === 0, [items]);
  return /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_jsx_runtime29.Fragment, { children: isEmpty === true ? /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
    EmptyCart,
    __spreadProps(__spreadValues({}, props), {
      title: cartHeaderText,
      subtitle: t("cart.emptyCart")
    })
  ) : /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
    Cart,
    __spreadProps(__spreadValues({}, props), {
      cartHeaderText,
      canBuy: isEmpty === false,
      total,
      items,
      onProductAdded: handleProductAdded,
      onProductRemoved: handleProductRemoved
    })
  ) });
}

// src/Products/ProductImage.tsx
var import_classnames9 = __toESM(require("classnames"));
var import_useutm5 = require("@gojiraf/useutm");
var import_jsx_runtime30 = require("react/jsx-runtime");
function ProductImage({
  url,
  title,
  onClick,
  onKeyPress,
  className
}) {
  const utmMedium = useUtmMediumFromURL();
  const { isTotemDevice } = (0, import_useutm5.useUtm)(utmMedium);
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("div", { id: "product-catalog-item-image-to-detail-button", className: (0, import_classnames9.default)("rounded-lg", className), style: { boxShadow: "5px 4px 6px 1px rgba(0,0,0,0.1)" }, onClick, onKeyPress, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("figure", { className: `${isTotemDevice ? "w-52 h-52" : "w-20 h-20 "}  mobileXs:w-18 mobileXs:h-18`, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)("img", { className: `${isTotemDevice ? "w-52 h-52" : "w-20 h-20"} mobileXs:w-18 mobileXs:h-18 object-cover rounded-lg`, alt: title, src: url }) }) });
}

// src/Kit/ListItem.tsx
var import_jsx_runtime31 = require("react/jsx-runtime");
var badgeStyleTotem = {
  "& .MuiBadge-badge": {
    fontSize: "2rem",
    padding: "7%"
  }
};
function ListItem({
  itemId,
  imageUrl,
  title,
  originalPrice,
  price,
  renderBottomLeft = null,
  renderMidLeft = null,
  renderBottomRight = null,
  className,
  onProductClicked
}) {
  const { discountText, discountValue } = getBadgeDiscount(originalPrice, price);
  const utmMedium = useUtmMediumFromURL();
  const { isTotemDevice } = (0, import_useutm6.useUtm)(utmMedium);
  return /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("div", { onClick: () => onProductClicked(itemId), className: (0, import_classnames10.default)("max-h-32 snap-start scroll-mt-4 overflow-visible", className), children: /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)("div", { className: "flex gap-4", children: [
    discountValue > 0 ? /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
      StyledBadge,
      {
        color: "primary",
        customStyles: { marginRight: "0.625rem", fontWeight: "600", top: "0.313rem" },
        badgeContent: discountText,
        sx: isTotemDevice ? badgeStyleTotem : {},
        children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
          ProductImage,
          {
            url: imageUrl,
            title,
            onClick: () => {
            },
            onKeyPress: () => {
            }
          }
        )
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
      ProductImage,
      {
        url: imageUrl,
        title,
        onClick: () => {
        },
        onKeyPress: () => {
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)("div", { className: "flex flex-col grow justify-between min-w-0", children: [
      /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
        "h2",
        {
          style: {
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            display: "-webkit-box"
          },
          className: "overflow-hidden text-ellipsis text-xs font-medium capitalize break-words",
          children: title
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)("div", { className: "flex-1 flex items-center justify-between", children: [
        /* @__PURE__ */ (0, import_jsx_runtime31.jsxs)("div", { children: [
          renderMidLeft,
          renderBottomLeft
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime31.jsx)("div", { className: "flex flex-col self-end h-1/2", children: renderBottomRight })
      ] })
    ] })
  ] }) });
}

// src/Products/ProductItem.tsx
var import_jsx_runtime32 = require("react/jsx-runtime");
function ProductItem({
  product,
  onProductClicked = () => {
  },
  className,
  propText
}) {
  var _a2;
  const { user: { role, tokens } } = (0, import_auth.useAuth)();
  const { isDesktop } = (0, import_responsive5.useDevices)();
  const { send } = (0, import_state10.useViews)();
  const { send: sendPDP } = usePDP();
  const isModerator = role === "MODERATOR";
  const { matomoTrackEvent } = (0, import_analytics7.useMatomoAnalytics)();
  const { t } = (0, import_react_i18next12.useTranslation)();
  const { sendEventPostToElastic } = (0, import_analytics7.useElasticEventTracker)();
  const { goJirafUsers: [{ id: callId }] } = (0, import_state10.useStore)((storeState) => storeState.store);
  const handleProductButton = () => {
    (0, import_analytics7.sendEventToElastic)(null, null, "InCall > Products", `click-product-detail-d [${product.name}]`, "");
    matomoTrackEvent("InCall > Products", `click-product-detail-d [${product.name}]`);
    sendEventPostToElastic("products", "click-product-detail", `${product.name}`);
    onProductClicked(product);
  };
  const viewProductFromImage = () => {
    (0, import_analytics7.sendEventToElastic)(null, null, "InCall > Products", `click-img-product-detail [${product.name}]`, "");
    matomoTrackEvent("InCall > Products", `click-img-product-detail [${product.name}]`);
    sendEventPostToElastic("products", "click-img-product-detail", `${product.name}`);
    sendPDP({
      type: "SET_PRODUCT",
      productId: product.id
    });
    send({
      type: "SHOW_PDP"
    });
  };
  const handleClick = (e) => {
    e.stopPropagation();
    matomoTrackEvent("InCall > Products > PDP ", `buy-from-pdp [${product.name}]`);
    (0, import_analytics7.sendEventToElastic)(null, null, "InCall > Products > PDP ", `buy-from-pdp [${product.name}]`, product.name);
    if (isModerator && product.main === false) {
      highlightProduct({ callId, productId: product.id, accessToken: tokens == null ? void 0 : tokens.accessToken });
      return;
    }
    handleProductButton();
  };
  const getButtonViewProductText = () => {
    if (propText)
      return propText;
    if (isModerator && product.main === false)
      return t("products.highlightProduct");
    if (isDesktop)
      return t("products.viewProduct");
    return t("products.buy");
  };
  const hasLabel = ((_a2 = product.skus) == null ? void 0 : _a2.length) > 1 && (0, import_lodash4.default)(product.skus, "price").length > 1;
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
    ListItem,
    {
      className: (0, import_classnames11.default)("cursor-pointer bg-white", className),
      itemId: product.id,
      imageUrl: product.skus[0].images[0].imageUrl,
      originalPrice: product.originalPrice,
      price: product.price,
      onProductClicked: () => viewProductFromImage(),
      title: product.name,
      renderMidLeft: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
        ProductPrice,
        {
          hasLabel,
          from: product.price,
          to: product.originalPrice
        }
      ),
      renderBottomRight: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
        ButtonViewProduct,
        {
          id: "product-catalog-item-button-to-detail",
          className: `${isDesktop ? "text-black bg-white hover:bg-white" : ""} text-[10px] font-light uppercase leading-4 tracking-widest px-3 h-2`,
          onClick: handleClick,
          children: getButtonViewProductText()
        }
      )
    }
  );
}
ProductItem.defaultProps = {
  propText: ""
};

// src/Products/ProductsList.tsx
var import_react_i18next13 = require("react-i18next");
var import_jsx_runtime33 = require("react/jsx-runtime");
function ProductsList({
  products,
  renderItem,
  clearSearch
}) {
  const { t } = (0, import_react_i18next13.useTranslation)();
  return /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(
    List,
    {
      clearSearch,
      emptyListText: t("products.productListEmpty"),
      items: products,
      renderItem
    }
  );
}

// src/Products/Products.tsx
var import_jsx_runtime34 = require("react/jsx-runtime");
function Products({
  canBuy = false,
  paymentType,
  products = [],
  getProductsBySearchTerm,
  onItemClicked = () => {
  },
  isLightIntegration
}) {
  const { matomoTrackEvent } = (0, import_analytics8.useMatomoAnalytics)();
  const [searchTerm, setSearchTerm] = (0, import_react13.useState)("");
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
    ProductsCard,
    {
      searchTerm,
      setSearchTerm,
      getProductsBySearchTerm,
      paymentType,
      isLightIntegration,
      canBuy,
      renderList: /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
        ProductsList,
        {
          clearSearch: () => {
            matomoTrackEvent("InCall > Products", "click-button-another-products");
            (0, import_analytics8.sendEventToElastic)(null, null, "InCall > Products", "click-button-another-products", "");
            setSearchTerm("");
            getProductsBySearchTerm("");
          },
          products,
          renderItem: (product) => /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(
            ProductItem,
            {
              className: "py-7",
              product,
              onProductClicked: () => onItemClicked(product.id)
            },
            product.id
          )
        }
      )
    }
  );
}

// src/Products/ProductsView.tsx
var import_jsx_runtime35 = require("react/jsx-runtime");
function ProductsView({
  products,
  getProductsBySearchTerm,
  onBuyClicked,
  defaultDeliveryMethod,
  hasOwnCheckoutIntegration,
  getItemsInCart,
  handlePIPLightIntegration,
  handleAddProductToCart,
  isLightIntegration
}) {
  var _a2, _b;
  const { i18n, t } = (0, import_react_i18next14.useTranslation)();
  const { state, send: sendViews } = (0, import_state11.useViews)();
  const { send: sendPDP } = usePDP();
  const { state: cartState } = useCart();
  const {
    paymentGateways,
    lang,
    storeConfigurations
  } = (0, import_state11.useStore)((storeState) => storeState.store);
  const { type: paymentType } = paymentGateways.find((paymentGatewayItem) => paymentGatewayItem.isDefault === true);
  const cartHeaderText = (_a2 = storeConfigurations == null ? void 0 : storeConfigurations.storeCustomization) == null ? void 0 : _a2.cartHeaderText;
  (0, import_react14.useEffect)(() => {
    i18n.changeLanguage(lang);
  }, []);
  const handleItemClicked = (productId) => {
    sendPDP({
      type: "SET_PRODUCT",
      productId
    });
    sendViews({
      type: "SHOW_ITEM"
    });
  };
  const showProducts = () => {
    sendViews({
      type: "GO_BACK"
    });
  };
  const canBuy = (0, import_react14.useMemo)(
    () => {
      var _a3;
      return !!((_a3 = cartState.context.variants) == null ? void 0 : _a3.size);
    },
    [(_b = cartState.context.variants) == null ? void 0 : _b.size]
  );
  if (state.matches("primary.showingProducts")) {
    return /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
      Products,
      {
        paymentType,
        isLightIntegration,
        canBuy,
        products,
        getProductsBySearchTerm,
        onItemClicked: handleItemClicked
      }
    );
  }
  if (state.matches("primary.showingPDP")) {
    return /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
      PDPContainer,
      {
        getItemsInCart,
        handleAddProductToCart,
        hasOwnCheckoutIntegration,
        handleBuyClicked: onBuyClicked,
        handlePIPLightIntegration,
        isLightIntegration,
        goBack: showProducts
      }
    );
  }
  if (state.matches("primary.showingCart")) {
    return /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
      CartView,
      {
        items: [],
        total: 0,
        cartHeaderText: getDynamicText({ paymentType, textsOptions: { to_agree: t("cart.cartToAgree"), storeCustomizationText: cartHeaderText, defaultText: t("cart.cart") } }),
        payButtonText: getDynamicText({ paymentType, textsOptions: { to_agree: t("cart.payToAgree"), storeCustomizationText: "", defaultText: t("cart.pay") } }),
        defaultDeliveryMethod,
        renderList: null,
        onBuyClicked,
        onBackClicked: showProducts,
        canBuy: true,
        onProductAdded: () => {
        },
        onProductRemoved: () => {
        }
      }
    );
  }
  return /* @__PURE__ */ (0, import_jsx_runtime35.jsx)("div", { children: "ProductsView" });
}

// src/Products/ProductsProvider.tsx
var import_react_query2 = require("@tanstack/react-query");
var import_jsx_runtime36 = require("react/jsx-runtime");
var queryClient = new import_react_query2.QueryClient();
function ProductsProvider({ children }) {
  return /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(import_react_query2.QueryClientProvider, { client: queryClient, children });
}

// src/Products/FeatureProduct.tsx
var import_jsx_runtime37 = require("react/jsx-runtime");
function FeatureProduct({
  product,
  onProductClicked,
  propText
}) {
  return /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
    ProductItem,
    {
      propText,
      className: "rounded-lg p-[0.5rem]",
      product,
      onProductClicked
    }
  );
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  Cart,
  CartCard,
  CartCardCarso,
  CartCount,
  CartItem,
  CartView,
  DeliveryMethods,
  Draggable,
  FeatureProduct,
  FormattedPrice,
  MainHeading,
  Modal,
  PDPContainer,
  ProductImage,
  Products,
  ProductsProvider,
  ProductsView,
  StyledBadge,
  darken,
  defaultTheme,
  formatNumber,
  getBadgeDiscount,
  getCurrency,
  getDynamicText,
  toHsl,
  useCart,
  usePDP,
  useProducts
});
