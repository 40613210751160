import React, { useEffect, useState } from 'react'
import 'animate.css/animate.min.css'
import styled from 'styled-components'
import { sendEventToElastic, useMatomoAnalytics } from '@gojiraf/analytics'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../Kit/Buttons'
import { useCheckout } from '../../hooks/useCheckout'
import PropTypes from 'prop-types'
import { selectQuickAdd, setQuickAdd } from '../../reducers/uiSlice'

const QuickAddButton = ({ featuredProduct, store }) => {
  const { t } = useTranslation()
  const [animationClass, setAnimationClass] = useState('')
  const { matomoTrackEvent } = useMatomoAnalytics()
  const {
    handleQuickAddClick,
    getItemsInCart,
    clickBuyCheckout,
    setClickBuyCheckout,
    handleOnBuyClicked,
  } = useCheckout(store)
  const itemsInCart = getItemsInCart()
  const showQuickAdd = useSelector(selectQuickAdd)
  const dispatch = useDispatch()

  useEffect(() => {
    const executeAnimation = async () => {
      setAnimationClass('animate__backInUp animate__speed-1s')
      setTimeout(() => {
        setAnimationClass('animate__heartBeat animate__infinite')
      }, 1000)
      await new Promise((resolve) => {
        setTimeout(() => {
          setAnimationClass('animate__backOutDown')
          resolve()
        }, 7500)
      })
      setTimeout(() => {
        dispatch(setQuickAdd(false))
      }, 1500)
    }
    if (showQuickAdd) executeAnimation()
  }, [showQuickAdd])

  useEffect(() => {
    if (clickBuyCheckout) {
      const total = getItemsInCart().reduce((acc, item) => acc + item.price * item.quantity, 0)
      handleOnBuyClicked({ total, selectedDeliveryMethod: 'unavailable' })
      setClickBuyCheckout(false)
    }
  }, [itemsInCart])

  const handleClick = () => {
    handleQuickAddClick(featuredProduct)
    matomoTrackEvent('InCall > Products', `quick-buy [${featuredProduct.name}]`)
    sendEventToElastic({
      eventType: 'products',
      event: 'quick-buy',
      productName: `${featuredProduct.name}`,
    })
    setAnimationClass('animate__backOutDown')
    setTimeout(() => {
      dispatch(setQuickAdd(false))
    }, 1500)
  }

  return (
    <>
      {showQuickAdd ? (
        <Container>
          <Button
            className={`animate__animated ${animationClass}`}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            {t('products.buy')}
          </Button>
        </Container>
      ) : null}
    </>
  )
}

const Container = styled.div`
  display: flex;
  margin-top: 50%;
  scale: 1.75;
  z-index: 1;
`

QuickAddButton.propTypes = {
  featuredProduct: PropTypes.object,
  store: PropTypes.object,
}

export default QuickAddButton
