import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useViews } from 'state'
import { Reactions } from './Reactions'
import { FavoriteBorder, PeopleAltOutlined, PeopleAlt, LocalMallOutlined } from '@material-ui/icons'
import Badge from '@mui/material/Badge'
import { selectCurrentStore } from '../../reducers/storeSlice'
import {
  notifyLikeMeesage,
  selectIsLikeButtonVisible,
  selectRemoteLikeVisible,
  setLocalLikeAnimation,
} from '../../reducers/uiSlice'
import ShareButton from './ShareButton'
import { ChatButton } from './ChatButton'
import { RoundedButton } from '../Kit/Buttons'
import { selectUserCount } from '../../reducers/callSlice'
import { PIPButton } from '../GJKit/PIPButton'
import { useGoogleAnalytics, useElasticEventTracker, useMatomoAnalytics } from '@gojiraf/analytics'
import { useDevices } from '@gojiraf/responsive'

export const ActionBar = () => {
  const { isDesktop } = useDevices()
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()
  const dispatch = useDispatch()
  const store = useSelector(selectCurrentStore)
  const { isOneToManySale } = store
  const remoteLikeVisible = useSelector(selectRemoteLikeVisible)
  const isLikeButtonVisible = useSelector(selectIsLikeButtonVisible)
  const usersCount = useSelector(selectUserCount)
  const { state, send } = useViews()
  const { sendEventPostToElastic } = useElasticEventTracker()

  const showLike = () => {
    if (!remoteLikeVisible) {
      gaEventTracker('InCall', 'like-message-sent')
      sendEventPostToElastic('like-message-sent')
      matomoTrackEvent('InCall', 'like-message-sent')
      dispatch(notifyLikeMeesage())
      dispatch(setLocalLikeAnimation({ localLikeAnimation: true }))
      return
    }
    gaEventTracker('InCall', 'like-local-animation')
    sendEventPostToElastic('like-local-animation')
    matomoTrackEvent('InCall', 'like-local-animation')
    dispatch(setLocalLikeAnimation({ localLikeAnimation: true }))
  }

  const showParticipants = () => {
    gaEventTracker('InCall', 'show-participants-cameras-button')
    matomoTrackEvent('InCall', 'show-participants-cameras-button')
    send({ type: 'SHOW_PARTICIPANTS' })
  }

  const showProducts = () => {
    gaEventTracker('InCall > Products', 'toggle-products-button')
    matomoTrackEvent('InCall > Products', 'toggle-products-button')
    send({ type: 'SHOW_PRODUCTS' })
  }

  return (
    <ActionBarContainer isDesktop={isDesktop}>
      {!isOneToManySale && isDesktop && <ChatButton />}
      {!isOneToManySale && isDesktop && (
        <RoundedButton
          color="secondary"
          onClick={showParticipants}
          id="show-participants-cameras-button"
          data-test="show-participants-cameras-button"
        >
          <StyledBadge badgeContent={usersCount} max="99">
            {state.matches('secondary.showingParticipants') === true ? (
              <PeopleAlt />
            ) : (
              <PeopleAltOutlined />
            )}
          </StyledBadge>
        </RoundedButton>
      )}

      {isDesktop ? (
        <>
          <Spacer />
          <PIPButton isMobile={false} />
          <ShareButton />
        </>
      ) : (
        <>
          <ShareButton />
          <RoundedButton
            color="secondary"
            onClick={showProducts}
            id="toggle-products-button"
            data-test="toggle-products-button"
          >
            <LocalMallOutlined />
          </RoundedButton>
        </>
      )}

      {isLikeButtonVisible && (
        <>
          <RoundedButton
            id="like-button"
            color="secondary"
            aria-label="Favorite"
            onClick={showLike}
          >
            <Reactions data-test="like-button" />
            <FavoriteBorder />
          </RoundedButton>
        </>
      )}
      {!isOneToManySale && !isDesktop && (
        <RoundedButton
          color="secondary"
          onClick={showParticipants}
          id="show-participants-cameras-button"
          data-test="show-participants-cameras-button"
        >
          <StyledBadge badgeContent={usersCount} max="99">
            {state.matches('secondary.showingParticipants') === true ? (
              <PeopleAlt />
            ) : (
              <PeopleAltOutlined />
            )}
          </StyledBadge>
        </RoundedButton>
      )}
    </ActionBarContainer>
  )
}

const ActionBarContainer = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  ${({ isDesktop }) =>
    isDesktop &&
    css`
      background: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.5) 5rem,
        rgba(0, 0, 0, 0.5) 100%
      );
      padding: 1rem;
      padding-top: 0.5rem !important;
    `}
`

const Spacer = styled.div`
  flex-grow: 1;
`

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontFamily: 'Montserrat',
    backgroundColor: '#000',
  },
}))
